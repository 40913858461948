/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BusEmty from '@/views/Bus/BusEmty';
import Toast from "vux/src/components/toast/index.vue";
export default {
  components: {
    BusEmty: BusEmty
  },
  data: function data() {
    return {
      bh: '',
      busFx: 0,
      //方向0 正 1反
      BuslineList: [],
      //站点列表
      busNowInfo: [],
      //当前班车的信息
      isRefresh: false,
      //是否正在刷新
      runIndex: 0,
      //正在行驶
      acitveBus: false,
      //
      myPois: {},
      //我的定位
      myPh: -1,
      //
      bustimer: '',
      //班车刷新的关闭
      myTimer: '' //我自己的定位

    };
  },
  methods: {
    showRunBus: function showRunBus(i) {
      this.runIndex = i;
      this.acitveBus = true;
    },
    resClick: function resClick() {
      var _this = this;

      //  this.BuslineList.forEach((val) => {
      //       val.bus = ''
      //   })
      this.getCarInfo();
      this.onPosition();
      clearInterval(this.timer);
      this.timer = setInterval(function () {
        _this.getCarInfo();

        _this.onPosition();
      }, 1000 * 10);
    },
    swapFx: function swapFx() {
      //交换站点的方向
      this.busFx = this.busFx == 0 ? 1 : 0;
      this.myPh = -1;
      this.onPosition();
      this.acitveBus = false;
      this.BuslineList.reverse();
      this.BuslineList.forEach(function (val) {
        val.bus = '';
      });
      this.getCarInfo();
    },
    //获取当前车辆的站点
    getCarInfo: function getCarInfo() {
      var _this2 = this;

      this.isRefresh = true;
      setTimeout(function () {
        _this2.isRefresh = false;
      }, 1000); //   this.$utils.Tools.loadShow()

      this.$api.Bus.selectBusListByLine({
        bh: this.bh,
        busFx: this.busFx
      }).then(function (response) {
        //    this.$utils.Tools.loadHide();
        var code = response.code,
            msg = response.msg,
            data = response.data; //    this.$utils.Tools.loadHide()

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.busNowInfo = data;
          data.forEach(function (item) {
            _this2.BuslineList.forEach(function (val) {
              if (item.pointXh == val.pointOrder) {
                item.click = false;
                val.bus = item;
              } else {
                val.bus = '';
              }
            });
          });
        }
      }).catch(function () {
        _this2.isRefresh = false;

        _this2.$utils.Tools.httpError();
      });
    },
    //获取站点列表
    selectPointListByEntity: function selectPointListByEntity() {
      var _this3 = this;

      this.$api.Bus.selectPointListByEntity({
        bh: this.bh
      }).then(function (response) {
        //   this.$utils.Tools.loadHide()
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          //  this.startSite=data[0].pointName;
          //  this.endSite=data[data.length-1].pointName;
          _this3.BuslineList = data;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    // -----快速找当前最近的站点--------
    GetDistance: function GetDistance(lat1, lng1, lat2, lng2) {
      var radLat1 = lat1 * Math.PI / 180.0;
      var radLat2 = lat2 * Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * 6378.137; // EARTH_RADIUS;

      s = Math.round(s * 10000) / 10000;
      return s;
    },
    setConfig: function setConfig() {
      var _this4 = this;

      var localUrl = window.location.href.split('#')[0];
      this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          Toast(msg);
        } else {
          _this4.$utils.WeiXin.wxConfig(data);

          _this4.onPosition();
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    //获取当前的定位
    onPosition: function onPosition() {
      var _this5 = this;

      var that = this;
      that.$utils.Tools.loadShow('正在定位');
      setTimeout(function () {
        that.$utils.Tools.loadHide();
      }, 10000);
      that.$utils.WeiXin.wxGetLocationCity('allmap', 14, false, function (resp) {
        if (resp.flag === true) {
          that.$utils.Tools.loadHide();
          that.myPois = {
            longitude: resp.longitude,
            latitude: resp.latitude
          }; // that.myPois = {
          //     longitude: 121.419878,
          //     latitude: 37.429166,
          // }

          var list = [];
          that.BuslineList.forEach(function (line) {
            var a = that.GetDistance(that.myPois.longitude, that.myPois.latitude, line.gps.split(',')[0], line.gps.split(',')[1]);
            list.push(a);
          });
          var min = Math.min.apply(Math, list);
          list.some(function (item, i) {
            if (list[i] == min) {
              that.myPh = i;
            }
          }); //  this.selectPointListByEntity()
        } else {
          that.$utils.Tools.loadHide();
          that.$utils.Tools.toastShow(_this5.$t('location.failed'));
        }

        that.$utils.Tools.loadHide();
      });
    }
  },
  created: function created() {
    var _this6 = this;

    this.bh = this.$route.params.bh || this.$utils.Store.getItem('busbh');
    this.$utils.Store.setItem('busbh', this.bh);
    this.selectPointListByEntity();
    this.setConfig(); // this.onPosition()

    this.getCarInfo();
    this.timer = setInterval(function () {
      _this6.getCarInfo();

      _this6.onPosition();
    }, 1000 * 10);
  },
  beforeDestroy: function beforeDestroy() {
    this.$utils.Tools.loadHide();
    clearInterval(this.timer);
  }
};